import "./App.css";

function App() {
  return (
    <div className="div">
      <p className="p">
        <span className="roxo">vitinho</span>
        <span className="laranja">.</span>
        <span className="verde">dev</span>
      </p>
    </div>
  );
}

export default App;
